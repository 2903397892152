<template>
  <div class="manage-assigned-project-sales">
    <div class="container fluid">
      <div class="py-2">
        <h2 class="p-3">Manage Assigned Project Sales</h2>
        <fd-tabs class="card" containerClass="pt-2" route>
          <fd-route-tab
            label="Overview"
            :to="{ name: 'ManageAssignedProjectSalesOverview' }"
            iconClass="far fa-list-alt"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Reservation"
            :to="{ name: 'AssignedProjectReservationsManagement' }"
            iconClass="fas fa-calendar-alt"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Booking"
            :to="{ name: 'ManageAssignedProjectBooking' }"
            iconClass="fas fa-file-contract"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Mortgage"
            :to="{ name: 'ManageAssignedProjectMortgage' }"
            iconClass="fas fa-dollar-sign"
          >
            <router-view></router-view>
          </fd-route-tab>
          <fd-route-tab
            label="Lawyer"
            :to="{ name: 'ManageAssignedProjectLegalProcess' }"
            iconClass="fas fa-gavel"
          >
          </fd-route-tab>
          <fd-route-tab
            label="Vacant Posession"
            :to="{ name: 'ManageAssignedProjectVacantPossession' }"
            iconClass="fas fa-key"
          >
          </fd-route-tab>
        </fd-tabs>
        <div class="card py-2">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
